import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import fanDir from "../images/fan-directions.webp";

const h1ClassNames = "text-center font-bold text-3xl pb-5";
const h2ClassNames = "italic font-bold text-2xl pt-4";
const pClassNames = "pt-4 pl-2 text-lg";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-lg";
const buttonImageClasses = "flex pl-2 pr-2 pt-2 pb-2 w-full ";

const HvacPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Keeping the living room warm</h1>
          <p className={pClassNames}>
            The Overlook Chalet has a beautiful vaulted cieiling which offers
            beautiful views and welomes natural light. Heat, however, tends to
            rise and rest at the top. You'll notice that the upstairs reading
            nook is quite warm when the heat is on.
          </p>
          <p className={pClassNames}>
            To keep the temperature cozy and stable, we recommend that you turn
            on the ceiling fan. We know, a ceiling fan in the winter sounds
            crazy, but the clockwise rotation pushes the heat back down without
            cooling the room.
          </p>
          <h2 className={h2ClassNames}>Warm it up!</h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Set the heat to your preferred temperature using the Mysa
              controller.
            </li>
            <li className={liClassNames}>
              Turn on the ceiling fan. The switch is labeled.
            </li>
            <li className={liClassNames}>
              Use the remote to set the ceiling fan to medium or high (whatever
              is most comfortable), rotating clockwise.
            </li>
            <li className={liClassNames}>
              Cozy up and enjoy the warmth. Light a fire if you like!
            </li>
          </ol>
          <img
            className={buttonImageClasses}
            src={fanDir}
            alt="fan-directions"
          />
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default HvacPage;
